<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="签证申请资料ID｛visa_apply_profile.id｝" prop="profileId">
                <a-input v-model="queryParam.profileId" placeholder="请输入签证申请资料ID｛visa_apply_profile.id｝" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="税前工资" prop="grossWage">
                  <a-input v-model="queryParam.grossWage" placeholder="请输入税前工资" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="工资单位 元，万" prop="unit">
                  <a-input v-model="queryParam.unit" placeholder="请输入工资单位 元，万" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="公司/商户电话" prop="companyMobile">
                  <a-input v-model="queryParam.companyMobile" placeholder="请输入公司/商户电话" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="成立年份" prop="establishYear">
                  <a-input v-model="queryParam.establishYear" placeholder="请输入成立年份" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="cover letter下载链接" prop="coverLetterUrl">
                  <a-input v-model="queryParam.coverLetterUrl" placeholder="请输入cover letter下载链接" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="行程单下载链接" prop="itinerary">
                  <a-input v-model="queryParam.itinerary" placeholder="请输入行程单下载链接" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:applyAttr:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:applyAttr:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:applyAttr:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['user:applyAttr:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:applyAttr:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:applyAttr:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['user:applyAttr:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['user:applyAttr:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageApplyAttr,listApplyAttr, delApplyAttr } from '@/api/user/applyAttr'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ApplyAttr',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        profileId: null,
        logo: null,
        userName: null,
        birthday: null,
        passportNumber: null,
        companyName: null,
        jobPosition: null,
        schoolName: null,
        hiredate: null,
        grossWage: null,
        unit: null,
        companyMobile: null,
        companyAddress: null,
        establishYear: null,
        profession: null,
        departureTime: null,
        destination: null,
        departureCity: null,
        departureCountry: null,
        returnCountry: null,
        returnCity: null,
        returnTime: null,
        tripPurpose: null,
        financialCertificate: null,
        returnGuarantee: null,
        coverLetterUrl: null,
        itinerary: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签证申请资料ID｛visa_apply_profile.id｝',
          dataIndex: 'profileId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'logo',
          dataIndex: 'logo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '名字',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '出生日期',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '护照号码',
          dataIndex: 'passportNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司名称',
          dataIndex: 'companyName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '工作职位',
          dataIndex: 'jobPosition',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '学校',
          dataIndex: 'schoolName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '入职时间',
          dataIndex: 'hiredate',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '税前工资',
          dataIndex: 'grossWage',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '工资单位 元，万',
          dataIndex: 'unit',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司/商户电话',
          dataIndex: 'companyMobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '公司/商户地址',
          dataIndex: 'companyAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '成立年份',
          dataIndex: 'establishYear',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '专业',
          dataIndex: 'profession',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '出发时间',
          dataIndex: 'departureTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '目的地 多个用英文逗号隔开',
          dataIndex: 'destination',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '出发城市',
          dataIndex: 'departureCity',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '出发国家',
          dataIndex: 'departureCountry',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '返程国家',
          dataIndex: 'returnCountry',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '返程城市',
          dataIndex: 'returnCity',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '返程时间',
          dataIndex: 'returnTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '出行目的',
          dataIndex: 'tripPurpose',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '财务证明',
          dataIndex: 'financialCertificate',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '回国保证',
          dataIndex: 'returnGuarantee',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'cover letter下载链接',
          dataIndex: 'coverLetterUrl',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '行程单下载链接',
          dataIndex: 'itinerary',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询工作证明、行程单列表 */
    getList () {
      this.loading = true
     pageApplyAttr(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        profileId: undefined,
        logo: undefined,
        userName: undefined,
        birthday: undefined,
        passportNumber: undefined,
        companyName: undefined,
        jobPosition: undefined,
        schoolName: undefined,
        hiredate: undefined,
        grossWage: undefined,
        unit: undefined,
        companyMobile: undefined,
        companyAddress: undefined,
        establishYear: undefined,
        profession: undefined,
        departureTime: undefined,
        destination: undefined,
        departureCity: undefined,
        departureCountry: undefined,
        returnCountry: undefined,
        returnCity: undefined,
        returnTime: undefined,
        tripPurpose: undefined,
        financialCertificate: undefined,
        returnGuarantee: undefined,
        coverLetterUrl: undefined,
        itinerary: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delApplyAttr(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/applyAttr/export', {
            ...that.queryParam
          }, `工作证明、行程单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
